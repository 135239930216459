//fonts
$main-font: canada-type-gibson, sans-serif;
$font-fertigo: fertigo-pro, serif;

// Colours
$black: #000;
$white: #fff;
$orange: #b74f0d;
$orange-hover: #9D440C;
$brown: #2e281f;
$dark: #191510;
$khaki: #c1b6a7;
$error: #b00020;
$grey: #707070;
$grey-light: #909090;
$cream: #F2EFEC;

$menu-hover: $white;


// Screen Sizes
$mobile: 768px; //below 768px
$desktop: 769px; //above 769px
$nav-mobile-break: 1100px; // nav menu hide center menu
$nav-tiny-mobile-break: 394px; // nav menu hide logo


// navbar and slide menu variables
$nav-height: 65px;
$slidemenu-width: 290px;


// letter-spacing for fertigo pro font
$fertigo-spacing: 3px;


// Image sizes
$mobile-square: 230px;
$mobile-long: 330px;
$desktop-square: 628px;
$desktop-long: 901px;


// Font Sizes
$size_92: 92px;
$size_56: 56px;
$size_48: 48px;
$size_46: 46px;
$size_40: 40px;
$size_36: 36px;
$size_34: 34px;
$size_30: 30px;
$size_24: 24px;
$size_20: 20px;
$size_18: 18px;
$size_16: 16px;
$size_14: 14px;
$size_12: 12px;
$size_10: 10px;