@import './variables.scss';

// mobile screen size only
@mixin mobile-screen {
    @media screen and (max-width: $mobile) {
        @content;
    }
}

// Dynamic mobile screen size
@mixin mobile-screen-dynamic($size) {
    @media screen and (max-width: $size) {
        @content;
    }
}

// Desktop screen size only
@mixin desktop-screen {
    @media screen and (min-width: $desktop) {
        @content;
    }
}

// Dynamic Desktop screen size
@mixin desktop-screen-dynamic($size) {
    @media screen and (min-width: $size) {
        @content;
    }
}

// Dynamic screen height
@mixin more-than-height($height) {
    @media screen and (min-height: $height) {
        @content;
    }
}

// Dynamic screen height
@mixin less-than-height($height) {
    @media screen and (max-height: $height) {
        @content;
    }
}

//Placeholders
@mixin placeholder {
    ::-webkit-input-placeholder {@content;}
    :-moz-placeholder           {@content;}
    ::-moz-placeholder          {@content;}
    :-ms-input-placeholder      {@content;}  
}