@import '@styles/variables.scss';
@import '@styles/mixins.scss';


// The slidemenu container. Defines it's size and colours
.container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;    
}

// the slide menu
.themenu {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $brown;
    z-index: 1000;
    color: $white;
    overflow-y: auto;

    height: 100vh;
    @include mobile-screen {
        width: 100vw;
    }

    @include desktop-screen {
        width: $slidemenu-width;
    }
}

// The invisible container to clock off the menu
.clickoff {
    width: 0;
    @include desktop-screen {
        width: 100vw;
    }
    height: 100vh;
    background-color: $dark;
    z-index: 999;
    position: fixed;
    top: 0;
    right: 0;
}

// transition when slidemenu is hidden
.hide {
    transform: translate3d(-100vw, 0, 0);
    transition: transform 1s ease-in;
    pointer-events: none;

    &> .clickoff {
        opacity: 0;
    }
}

// transition when slidemenu is shown
.show {
    
    &> .themenu {
        transform: translate3d(0vw, 0, 0);
        transition: transform 0.8s cubic-bezier(0, .52, 0, 1);
    }

    &> .clickoff {
        opacity: 0.5;
        transition: opacity 0.8s ease-in-out;
    }
}

// the items in the nav menu
.menuitems {
    display: block;

    @include desktop-screen {
        margin-left: 71px;
        margin-top: 90px;
        
    }

    @include mobile-screen {
        text-align: center;
        margin-bottom: 2em;
    }
}

// a group category of nave menu items
.menuGroup {
    margin-top: 2rem;

    h2 {
        display: inline-block;
        margin-bottom: 0.2em;
        color: $khaki;
        &:hover {
            color: $orange-hover;
        }
    }

    ul li {
        margin-bottom: 0.3em;
    }
}

.close {
    color: $white;
    font-size: 20px;
    position: fixed;
    top: 2.3rem;
    left: 1.8rem;
    background-color: Transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
}

.logo {
    @include desktop-screen {
        position: fixed;
        left: 71px;
        top: 1.5rem;
    }

    @include mobile-screen {
        display: flex;
        justify-content: center;
        margin-top: 1.5rem;
    }
}
